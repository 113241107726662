import SvgIcon from '@material-ui/core/SvgIcon';

function InstagramIcon(props) {
    return (
      <SvgIcon {...props}>
        <svg width="100%" height="100%" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M16.9989 10.6644C13.8771 10.6644 11.3295 13.062 11.3295 16C11.3295 18.938 13.8771 21.3356 16.9989 21.3356C20.1206 21.3356 22.6682 18.938 22.6682 16C22.6682 13.062 20.1206 10.6644 16.9989 10.6644ZM34.0027 16C34.0027 13.7905 34.024 11.601 33.8921 9.39554C33.7603 6.83381 33.1393 4.56027 31.1489 2.68701C29.1542 0.809737 26.7427 0.229345 24.0207 0.105261C21.673 -0.018823 19.3466 0.00119058 17.0031 0.00119058C14.6554 0.00119058 12.329 -0.018823 9.98556 0.105261C7.26359 0.229345 4.84784 0.813739 2.8574 2.68701C0.862706 4.56427 0.24601 6.83381 0.114164 9.39554C-0.0176811 11.605 0.00358439 13.7945 0.00358439 16C0.00358439 18.2055 -0.0176811 20.399 0.114164 22.6045C0.24601 25.1662 0.86696 27.4397 2.8574 29.313C4.85209 31.1903 7.26359 31.7707 9.98556 31.8947C12.3333 32.0188 14.6597 31.9988 17.0031 31.9988C19.3508 31.9988 21.6773 32.0188 24.0207 31.8947C26.7427 31.7707 29.1584 31.1863 31.1489 29.313C33.1436 27.4357 33.7603 25.1662 33.8921 22.6045C34.0282 20.399 34.0027 18.2095 34.0027 16ZM16.9989 24.2095C12.1716 24.2095 8.27582 20.5431 8.27582 16C8.27582 11.4569 12.1716 7.79045 16.9989 7.79045C21.8261 7.79045 25.7219 11.4569 25.7219 16C25.7219 20.5431 21.8261 24.2095 16.9989 24.2095ZM26.0792 9.37152C24.9521 9.37152 24.042 8.51494 24.042 7.45423C24.042 6.39351 24.9521 5.53693 26.0792 5.53693C27.2063 5.53693 28.1164 6.39351 28.1164 7.45423C28.1168 7.7061 28.0643 7.95556 27.962 8.18832C27.8598 8.42108 27.7097 8.63256 27.5205 8.81066C27.3313 8.98876 27.1065 9.12998 26.8592 9.22622C26.6119 9.32246 26.3468 9.37184 26.0792 9.37152Z" 
                fill="#686041"/>
        </svg>
      </SvgIcon>
    );
  };

  export default InstagramIcon;