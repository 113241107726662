import React, { useState, useEffect } from "react";
import axios from "axios";
import { URL } from "../../../middlewares/request";
import "./productDisplay.css";
import { splitPrix } from "../../../utilities";


const ProductDisplay = ({ produit }) => {
  const [panierItem, setPanierItem] = useState(null);
  const [supplements, setSupplements] = useState(null);
  const [supplementsPayants, setSupplementsPayants] = useState([]);

  console.log("====================================")
 console.log(produit)

  return (
    <div>
      {produit ? (
        <div>
          <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
            <p className="title-details">
              {produit.quantite}x {produit.nom}{" "} <span className="ecarter-prix">{(produit.prix * produit.quantite).toFixed(2)} €</span>
            </p>

            {/* tester si c'est pizza, car sa structure est differente des autres*/}
            {produit.entreeSelected && (
              <>
                <p className="bold-details">Entrée</p>
                <p>{produit.entreeSelected}</p>
              </>
            )}
            {produit.sandPlatSelected && (
              <>
                <p className="bold-details">Plats</p>
                <p>{produit.sandPlatSelected}</p>
              </>
            )}
            {produit.dessertSelected && (
              <>
                <p className="bold-details">Dessert</p>
                <p>{produit.dessertSelected}</p>
              </>
            )}
            {produit.boissonSelected && (
              <>
                <p className="bold-details">Boisson</p>
                <p>{produit.boissonSelected}</p>
              </>
            )}
            {produit.sauceSelected && (
              <>
                <p className="bold-details">Sauce</p>
                <p>{produit.sauceSelected}</p>
              </>
            )}
            {produit.categorie === 14 ? (
              <>
                <p className="bold-details">Marinade</p>
                <p>{produit.marinadeSelected}</p>
              </>
            
            ) : null }
             {(produit.hasOwnProperty("accompagnement_supp") && produit.accompagnement_supp.length 
             >0) && (
              <>
                <p className="bold-details">Accompagnement</p>
                <p >{produit.accompagnement_solo}</p>
              </>
            )}
            {(produit.hasOwnProperty("accompagnement_solo") && produit.accompagnement_solo != null
             ) && (
              <>
                <p className="bold-details">Accompagnement</p>
                <p >{produit.accompagnement_solo}</p>
              </>
            )}
             {!produit.accompagnement_supp || produit.accompagnement_supp?.length === 0 ? ("") : (
                <>
                  <p className="bold-details">Accompagnements supplémentaire</p>
                  <p>{produit.accompagnement_supp?.nom}</p>
                </>
              )}
              {produit?.information && (
            <>
              <p className="bold-details">Information :</p>
              <p>{produit.information}</p>
            </>
          )}
           
            
           
            </div>
          

        </div>
      ) : (
        <p>Erreur, panier pas reçu</p>
      )}
    </div>
  );
};

export default ProductDisplay;
