import Img_Menu1 from '../../images/Menu1.png';
import Img_Menu2 from '../../images/Menu2.png';
import Img_Menu3 from '../../images/thewraps.jpg';
import Img_Menu4 from '../../images/desserts.jpg';

export const MenuContent = [
    {
        id: 1,
        titre: "Entrées",
        img: Img_Menu1
    },
    {
        id: 2,
        titre: "The Tartines",
        img: Img_Menu2
    },
    {
        id: 3,
        titre: "Wraps Signatures",
        img: Img_Menu3
    },
    {
        id: 4,
        titre: "Desserts",
        img: Img_Menu4
    }
    
]