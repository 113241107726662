import { useHistory } from "react-router-dom";
import React from "react";
import ReactDOM from "react-dom";
import qrcode from '../../../images/qrcode.png';

import "./qrcode.css";
function Menucode() {
  const history = useHistory();
  const faireRedirection = () => {
    let url = "/admin/Gestion_commerciale";
    history.push(url);
  };



  return (
    <div className="Container">
      <div className="Qrcode-header">
        <h1>Menu QR Code</h1>
      </div>
      <div className="nom">Gonak</div>
      <div className="Gestioncommercial">
				<div className="QrcodeContent">
					<a href="https://gonak.fr/home/carte">
						<img src={qrcode} alt="QR Code" />
					</a>
				</div>
				<div className="societe_button">
					<a href={qrcode} target="_blank" download>
						<button className="reg_button" type="submit">
							Télecharger
						</button>
					</a>
				</div>
			</div>
    </div>
  );
}
export default Menucode;
