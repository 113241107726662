import SvgIcon from '@material-ui/core/SvgIcon';

function SnapChatIcon(props) {
    return (
      <SvgIcon {...props}>
        <svg width="100%" height="100%" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.77206 6.04377C6.97206 7.83577 7.28806 11.0464 7.41606 13.2918C6.55206 13.7704 5.44273 12.9304 4.81473 12.9304C4.16139 12.9304 3.38139 13.3598 3.25873 13.9998C3.17073 14.4611 3.37739 15.1331 4.86006 15.7184C5.43339 15.9451 6.79739 16.2118 7.11339 16.9558C7.55739 18.0011 4.83339 22.8264 0.556059 23.5304C0.394569 23.5571 0.248631 23.6425 0.14626 23.7703C0.0438884 23.898 -0.0077018 24.059 0.00139261 24.2224C0.0760593 25.5224 2.99073 26.0318 4.28273 26.2318C4.41473 26.4104 4.52139 27.1651 4.69073 27.7398C4.76673 27.9971 4.96273 28.3051 5.46673 28.3051C6.12406 28.3051 7.21606 27.7984 9.11739 28.1131C10.9814 28.4238 12.7334 31.0664 16.0974 31.0664C19.2241 31.0664 21.0894 28.4118 22.8841 28.1131C23.9227 27.9411 24.8147 27.9958 25.8121 28.1904C26.4987 28.3251 27.1147 28.3998 27.3107 27.7251C27.4827 27.1424 27.5881 26.4024 27.7174 26.2278C28.9974 26.0291 31.9254 25.5211 31.9987 24.2211C32.0078 24.0577 31.9562 23.8966 31.8539 23.7689C31.7515 23.6412 31.6055 23.5558 31.4441 23.5291C27.2387 22.8358 24.4321 18.0251 24.8867 16.9544C25.2014 16.2118 26.5561 15.9478 27.1401 15.7171C28.2254 15.2891 28.7694 14.7624 28.7574 14.1531C28.7427 13.3731 27.8041 12.9078 27.1134 12.9078C26.4107 12.9078 25.4014 13.7398 24.5841 13.2891C24.7121 11.0251 25.0267 7.82911 24.2281 6.03844C22.7147 2.64777 19.3481 0.933105 15.9827 0.933105C12.6387 0.933105 9.29739 2.62377 7.77206 6.04377Z" fill="#686041"/>
        </svg>
      </SvgIcon>
    );
  };

  export default SnapChatIcon;