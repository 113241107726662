import React, { Component } from 'react';
import './Header.css';


class Header extends Component {
    render() {
        return (
            <div id="homeAcceuil">
                    <div className="container-fluid Fond_Acceuil_Gonak">
                        <div className="row">
                            <div className="col-md-6">
                                 <span className="Text_Fond_Acceuil">GONAK<br></br>
                                 <p>L'Atelier des gourmands</p>
                                   </span>
                                   <div className="text-start">
                                   <span className="description_fond_acceuil">
                                   Découvrez notre <span className="coloredText"> cuisine </span> elaborée à
                                   partir de<span className="coloredText"> produits </span>frais et de <span className="coloredText">qualité</span>,
                                   dans un cadre <span className="coloredText">chaleureux</span> et <span className="coloredText">familial</span>.
                                       </span>
                                       </div>
                                    <a href="/home/carte">
                                        <form className="form-inline">
                                                <button  className="btn btn-outline mr-auto Btn_Reserver_Acceuil" type="button">COMMANDER</button>
                                        </form>
                                    </a>
                            </div>
                        </div>
                    </div>
            </div>
        );
    }
}

export default Header;