import SvgIcon from '@material-ui/core/SvgIcon';

function FacebookIcon(props) {
    return (
      <SvgIcon {...props} >
        <svg width="100%" height="100%" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.20861 0C2.76931 0 0 2.60623 0 5.843V26.157C0 29.3937 2.76931 32 6.20861 32H17.9075V19.49H14.3926V14.986H17.9075V11.138C17.9075 8.1148 19.9844 5.339 24.7685 5.339C26.7056 5.339 28.138 5.51401 28.138 5.51401L28.0253 9.72001C28.0253 9.72001 26.5645 9.70702 24.9704 9.70702C23.2452 9.70702 22.9685 10.4551 22.9685 11.697V14.986H28.1624L27.9361 19.49H22.9685V32H27.7937C31.233 32 34.0023 29.3938 34.0023 26.157V5.84303C34.0023 2.60627 31.233 3.19999e-05 27.7937 3.19999e-05L6.20861 0Z" 
                fill="#686041"/>
        </svg>
      </SvgIcon>
    );
  };

  export default FacebookIcon;