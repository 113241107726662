import axios from "axios";
import { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import ListeContact from "./ListeContact";
import "./contact.css";
import {URL} from "../../../middlewares/request";
import {
  changenouvelleReservationLength,
} from "../../../app/Redux-slices/adminSlice";


const FichierReservation = () => {
  const dispatch = useDispatch();
  const [contacts, setContacts] = useState([]);


  const fetchContacts = async () => {
    const { data } = await axios.get(
      URL + "restaurant/reservation/?ordering=-id"
    );
    setContacts(data);
  };
  useEffect(() => {
    console.log("Contacts longueur: ",contacts.length)
    // a chaque fois que commande change, on met a jour la longueur de nouvelle commande
    dispatch(changenouvelleReservationLength(contacts.length));
  }, [contacts, dispatch]);


  useEffect(() => {
    let timeoutId;
    function getLatestContacts() {
      fetchContacts();

      // wait for the response from fetchContacts , before we recall it (delay of 1minute)
      timeoutId = setTimeout(getLatestContacts, 1000 * 10);
      }
    getLatestContacts();

    return () => {
      clearTimeout(timeoutId);
      setContacts([]);
    };
  }, []);


      return (
        <div className='nouvelleCommande admin__container'>
          <h1
            style={{
              textAlign: "center",
              marginBottom: "2rem",
              color: "rgb(86 85 85)",
            }}>
            {contacts.length
              ? "Vos nouvelles réservations"
              : "Pas de nouvelles réservations"}
          </h1>
          <ListeContact
            contacts={contacts}
          />

        </div>
      );
};

export default FichierReservation;
