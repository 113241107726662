import {useState, useEffect, } from "react";
import "./panier.css";
import { useSelector } from "react-redux";
import { selectBaskets } from "../../../app/Redux-slices/basketsSlice";
//import Empty from "../../../images/empty.png";
import Product from "./product/Products";
import RadioButtonsGroup from "./RadioPanier";
import { calculTotal, getNombresArticles } from "../../../utilities";
import { useHistory } from "react-router-dom";

const Panier = () => {
  const history = useHistory();
  const baskets = useSelector(selectBaskets);

  const [reduction, setReduction] = useState(0); /// Use for the promotional code
  const [reductionMessage, setReductionMesssage] = useState(false) // Display promotional message of a valid code is applied
  const [reductionPrixTotal, setReductionPrixTotal] = useState((reduction > 0) ? (calculTotal(baskets) - (calculTotal(baskets)*reduction/100)).toFixed(2) : 0);

  function handleCallback(percent_off){ // Retrieve data from the child, for the reduction
    setReduction(percent_off)
    if(percent_off > 0){
      setReductionMesssage(true)
      setReductionPrixTotal((calculTotal(baskets) - (calculTotal(baskets)*reduction/100)).toFixed(2))//Ici pour changer le prix du père (paiement)
    }

  }

  useEffect(() => {
    // Caculate the price reduction which take a %
    setReductionPrixTotal((reduction > 0) ? (calculTotal(baskets) - (calculTotal(baskets)*reduction/100)).toFixed(2) : 0) 
  })

  return (
    <div id="panier">
      <h1>Panier</h1>
    <div>
      {/*<button onClick={history.goBack} className="go-back">
            Retour
      </button>*/}

      
    </div>
      {!baskets.length ? (
        <div className="panier__vide">

          <h2 className="doree">Votre panier est vide</h2>
        </div>
      ) : (
        <div className="panier__container">

            <h2><u>Détail du panier</u></h2>
            <p className="panier__nombre-articles">
              {getNombresArticles(baskets)} article
              {getNombresArticles(baskets) > 1 && "s"}
              {console.log({baskets})}
            </p>
            {baskets.map((product) => {
              return (
                <>
                  <Product key={product.nom} product={product} />
                </>
             );
            })}

            <div className="panier__container--prix">
              {/*============= Print the reduction =============*/}
              <div className="reducMsg">
                {(reduction > 0) ? ("Une réduction de : " + reduction + "% a été appliquée") : ("")}
              </div>
            {/*============= End print the reduction =============*/}
              <p>
                Prix Total{" "}
                <span style={{ fontSize: "1rem", color: "black" }}>(TTC)</span>
              </p>
              <p>{calculTotal(baskets)} €</p>
            </div>

            {/*============= START : Print the total price after reduction =============*/}
            <div className="panier__container--promo">
              {reductionMessage ? (
                <p>Prix après réduction : <span className="spanPrixReduction">{reductionPrixTotal} €</span></p>
                ) : (null)
              }
            </div >
          {/*============= END : Print the total price after reduction =============*/}

            <div className="separation_ligne"> </div>

            <RadioButtonsGroup parentCallback = {handleCallback} reductionPrixTotal = {reductionPrixTotal}/>
        </div>
      )}
    </div>
  );
};

export default Panier;
