import React, { useState, useEffect } from 'react';
import './styles.css';
import axios from 'axios';
import { CarteContent } from '../../../../data/site/carteContent';
import Grid from '@material-ui/core/Grid';
import { URL, sendrequest } from "../../../../middlewares/request";
import ProduitDetail from "../CartePage/produitDetail";

const CartePageMobile = (props) => {
    const idMenu = props.idMenuPageMobile;
    const [produits, setProduits] = useState([]);
    const [salades, setSalades] = useState([]);
    const [pates, setPates] = useState([]);
    const [wraps, setWraps] = useState([]);
    const [sandwichs, setSandwichs] = useState([]);
    const [entrees, setEntrees] = useState([]);
    const [tartines, setTartines] = useState([]);
  
    const [accompagnements, setAccompagnements] = useState([]);
    const [desserts, setDesserts] = useState([]);
    const [ingredients, setIngredients] = useState([]);
    const [accompagnementsProduit, setAccompagnementsProduit] = useState([]);
    const [fritesMaison, setFritesMaison] = useState([]);
    const [fritesEpice, setFritesEpice] = useState([]);
    const [boissonKid, setBoissonKid] = useState([]); // Boisson kid
    const [dessertKid, setDessertKid] = useState([]); // Dessert kid
  
  
  
  
    const [idModal, setIdModal] = useState(1);
    const [show, setShow] = useState(false);
  
  
      const fetchProduit = () => {
        axios.get(URL + "restaurant/produit/?categorie=" + idMenu).then((res) => {
          setProduits(res.data);
          console.log(res.data);
        });
        axios.get(URL + "restaurant/produit/?categorie=34").then((res) => { // Boisson Menus Kids
          setBoissonKid(res.data);
          //console.log(res.data);
        });
        axios.get(URL + "restaurant/produit/?categorie=35").then((res) => { // Dessert Menus Kids
          setDessertKid(res.data);
          //console.log(res.data);
        });
        axios.get(URL + "restaurant/produit/?categorie=2").then((res) => { // Entrée
          setEntrees(res.data);
          console.log(res.data);
        });
        axios.get(URL + "restaurant/produit/?categorie=3").then((res) => { // Salade
          setSalades(res.data);
          console.log(res.data);
        });
        axios.get(URL + "restaurant/produit/?categorie=7").then((res) => { // Pâte
          setPates(res.data);
          console.log(res.data);
        });
        axios.get(URL + "restaurant/produit/?categorie=8").then((res) => { // Wrap
          setWraps(res.data);
          console.log(res.data);
        });
        axios.get(URL + "restaurant/produit/?categorie=9").then((res) => { // Sandwich
          setSandwichs(res.data);
          console.log(res.data);
        });
        axios.get(URL + "restaurant/produit/?categorie=17").then((res) => { // Tartines
          setTartines(res.data);
          console.log(res.data);
        });
        axios.get(URL + "restaurant/produit/?categorie=25").then((res) => { // Accompagnements
          setAccompagnements(res.data);
          console.log(res.data);
        });
        axios.get(URL + "restaurant/produit/?categorie=11").then((res) => { // Desserts
          setDesserts(res.data);
          console.log(res.data);
        });
        axios.get(URL + "restaurant/ingredient/").then((res) => { // Boissons + Marinades + Sauces + Milkshakes + Smoothies
          setIngredients(res.data);
          console.log(res.data);
        });
        axios.get(URL + "restaurant/accompagnement/").then((res) => { // Accompagnements by item
          setAccompagnementsProduit(res.data);
          console.log(res.data);
        });
        
      };

      useEffect(() => {
        fetchProduit();
        //console.log(produitsCarte);
      }, []);
  
      const handleClose = (e) => {
          if (
            e.target.classList.contains("myModal__backdrop") ||
            e.target.classList.contains("myModal__modal__close-btn") ||
            e.target.parentNode.classList.contains("myModal__modal__close-btn") ||
            e.target.parentNode.parentNode.classList.contains(
              "myModal__modal__close-btn"
            )
          ) {
            setShow(false);
        }
      };

    useEffect(() => {
      fetchProduit();
      //console.log(produitsCarte);
    }, []);

    return (
        <div id="menu-page-mobile">
                <Grid container direction="row" justifyContent="center">
                    { produits.map((data) => (
                      
                          <ProduitDetail 
                          detail={data}
                          entrees={entrees}
                          tartines={tartines}
                          salades={salades}
                          pates={pates}
                          wraps={wraps}
                          sandwichs={sandwichs}
                          accompagnements={accompagnements}
                          desserts={desserts}
                          ingredients={ingredients}
                          accompagnementsProduit={accompagnementsProduit}
                          boissonKid={boissonKid}
                          dessertKid={dessertKid}
                          /> 
                        
                    ))}
                  </Grid>
        </div>
    );
};

export default CartePageMobile;