import React, { useState, useEffect } from 'react';
import './styles.css';
import TextField from '@material-ui/core/TextField';
import { makeStyles, withStyles, createTheme } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputBase from '@material-ui/core/InputBase';
import { Grid } from '@material-ui/core';
//Smooth Scroll with the link
import { HashLink } from 'react-router-hash-link';
import useForm from "../../../../hooks/useForm";
import { sendrequest } from "../../../../middlewares/request";
import Modal from "./modal/Modal";

const Theme_Custom = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1200,
      xl: 1920,
    },
  },
})



const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase);

var date = new Date()
var nowDate = date.getFullYear() + "-" + ((date.getMonth() + 1) < 10 ? "0"+(date.getMonth() + 1) : (date.getMonth() + 1)) + "-" + date.getDate()
var nowHour = date.getHours()

const Reserver = () => {
  const initial = {
    nom: "",
    prenom: "",
    societe: "",
    email: "",
    telephone: "",
    date_selectionne: "",
    heure: "",
    nb_personnes: "",
  };

  const useStyles = makeStyles((theme) => ({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
      width: "100%",
      [Theme_Custom.breakpoints.up('md')]: {
        width: "25%",
      },

    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      border: "2px solid #FFFFFF",
      borderRadius: "5px",
      color: "#FFFFFF",

    },
  }));
  const classes = useStyles();

  const [success, setSuccess] = useState(false);
  const [show, setShow] = useState(false);


  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const isValid = (fieldValues = state) => {
    const validator = {};
    if ("nom" in fieldValues)
      validator.nom = state.nom ? null : "Le champ nom est obligatoire";

    if ("prenom" in fieldValues)
      validator.prenom = state.prenom
        ? null
        : "Le champ prénom est obligatoire";

    if ("email" in fieldValues)
      validator.email = /([a-zA-Z0-9-_.+]{4,})@.+\..+/.test(email)
        ? null
        : "Le format du mail n'est pas valide";
    if ("telephone" in fieldValues)
      validator.telephone = (/([0-9.+])/.test(telephone) && telephone.length >= 10)
        ? null
        : "Le format du telephone n'est pas valide";
    if ("date_selectionne" in fieldValues)
      validator.date_selectionne = state.date_selectionne
        ? null
        : "Le champ date est obligatoire";
    if ("heure" in fieldValues)
      validator.heure = state.heure
        ? null
        : "Le champ heure est obligatoire";
    if ("heure" in fieldValues)
      if (parseInt(state.date_selectionne.substr(8, 2)) === parseInt(nowDate.substr(8, 2))) {
        validator.heure = state.heure
        let askDate = new Date()
        askDate.setHours(validator.heure.substr(0,2), validator.heure.substr(3,2), 0)
        console.log(askDate.getTime())
        validator.heure = askDate.getTime() > date.getTime() ? null : "Veuillez choisir un horaire plus tard";
      }
    if ("nb_personnes" in fieldValues)
      validator.nb_personnes = state.nb_personnes
        ? null
        : "Le champ nombre de personnes est obligatoire";

    setErrors({ ...validator });

    // return boolean if and only if we pass a parameter for the function

    if (fieldValues === state) {
      return Object.values(validator).every((el) => !el);
    }
  };

  const { state, handleInputChange, errors, setErrors, reinitialiserState } =
    useForm(initial, isValid);

  const { nom, prenom, email, telephone, date_selectionne, heure, nb_personnes } = state;

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isValid()) {
      const response = await sendrequest("post", "restaurant/reservation/", state);
      if (response !== false) {
        setSuccess(true);
        handleShow();
        reinitialiserState();
      }
      else {
        setSuccess(false);
        handleShow();
      }
    }
  };

  return (
    <div id="reserver">

      <div className="container-fluid Container_Reserver_Accueil">
        <div className="row Div_Reserver_Accueil">
          <div className="col-md-12">
            <h1 id="Titre_Reserver_Accueil">Réserver</h1>
            <div className="Sous_Div_Reserver_Accueil">
              <form noValidate onSubmit={handleSubmit}>

                <Grid container alignItems="center" className="Grid_Container">

                  <TextField InputLabelProps={{ style: { color: 'white' } }}
                    id="filled-reserver"
                    label="Nom" required
                    name="nom"
                    variant="filled"
                    value={nom}
                    onChange={handleInputChange}
                  />
                  <span className="error">{errors.nom}</span>

                  <TextField id="filled-reserver" InputLabelProps={{ style: { color: 'white' } }}
                    label="Prénom" required
                    name="prenom"
                    value={prenom}
                    onChange={handleInputChange}
                    variant="filled" />
                  <span className="error">{errors.prenom}</span>


                  <TextField id="filled-reserver" InputLabelProps={{ style: { color: 'white' } }}
                    label="Votre mail" required
                    name="email"
                    value={email}
                    onChange={handleInputChange}
                    variant="filled" />
                  <span className="error">{errors.email}</span>

                  <TextField id="filled-reserver" InputLabelProps={{ style: { color: 'white' } }}
                    label="Téléphone" required
                    name="telephone"
                    value={telephone}
                    onChange={handleInputChange}
                    variant="filled" />
                  <span className="error">{errors.telephone}</span>


                  <TextField
                    id="filled-reserver"
                    label="Date"
                    type="date"
                    name="date_selectionne"
                    defaultValue=""
                    variant="filled"
                    className="datepicker"
                    value={date_selectionne}
                    onChange={handleInputChange}
                    InputLabelProps={{
                      style: { color: 'white' },
                      shrink: true,
                    }}
                    inputProps={{ min: nowDate, }}
                  />
                  <span className="error">{errors.date_selectionne}</span>


                  <NativeSelect
                    id="filled-reserver"
                    value={heure}
                    name="heure"
                    variant="filled"
                    onChange={handleInputChange}
                    input={<BootstrapInput />}
                  >
                    <option label="Heure" disabled></option>
                    <option value={"12h00"}>12H00</option>
                    <option value={"12h30"}>12H30</option>
                    <option value={"13h00"}>13H00</option>
                    <option value={"13h30"}>13H30</option>
                    <option value={"14h00"}>14H00</option>
                    <option value={"14h30"}>14H30</option>

                    <option value={""} disabled> </option>

                    <option value={"19h00"}>19H00</option>
                    <option value={"19h30"}>19H30</option>
                    <option value={"20h00"}>20H00</option>
                    <option value={"20h30"}>20H30</option>
                    <option value={"21h00"}>21H00</option>
                    <option value={"21h30"}>21H30</option>
                  </NativeSelect>
                  <span className="error">{errors.heure}</span>


                  <NativeSelect
                    id="filled-reserver"
                    value={nb_personnes}
                    name="nb_personnes"
                    variant="filled"
                    onChange={handleInputChange}
                    input={<BootstrapInput />}
                  >
                    <option label="Nb personnes" disabled></option>
                    <option value={1}>1</option>
                    <option value={2}>2</option>
                    <option value={3}>3</option>
                    <option value={4}>4</option>
                    <option value={5}>5</option>
                    <option value={6}>6</option>
                    <option value={7}>7</option>
                    <option value={8}>8</option>
                    <option value={9}>9</option>
                    <option value={10}>10</option>
                  </NativeSelect>
                  <span className="error">{errors.nb_personnes}</span>


                </Grid>

                <button type="submit" className="btn btn-outline mr-auto Btn_Reserver_Acceuil_Reserver">RESERVER</button>

                <Modal
                  handleClose={handleClose}
                  show={show}
                  title={
                    <>
                      {success ? "Envoyé" : "Oups"}
                      <i
                        className={`${success ? " fas fa-thumbs-up" : " fas fa-thumbs-down"
                          }`}
                        style={{
                          color: "var(--primary-color)",
                          marginLeft: "1rem",
                        }}
                      ></i>
                    </>
                  }
                >
                  {success
                    ? "Votre message a bien été envoyé."
                    : "Une erreur s'est produite, veuillez réessayer."}
                </Modal>


              </form>

            </div>



          </div>

        </div>




      </div>



    </div>
  );
}
export default Reserver;