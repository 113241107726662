import React, { Component, Fragment } from 'react';
import './Description.css'
import PetitLogo from '../../../../images/petitgonak.png'

class Description extends Component {
    render() {
        return (
            <div id="description">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 p-5 DivBas_Acceuil">
                            <div className="Grande_Div text-center">
                            <img className="petitlogo" src={PetitLogo} /><br/>
                                <span className="Text_DivBas_Acceuil">
                                    <strong>“La<span className="coloredText"> gourmandise</span> est le <span className="coloredText">péché </span>des <span className="coloredText"> bonnes âmes ” !</span></strong><br/>
                                    <div className="Div_Citation"> 
                                    <p id="Text_PaulBocuse">Paul Bocuse</p>
                                    </div>
                            </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Description;